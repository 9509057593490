import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ae0e606e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'networkIcon',
  props: {
    size: {
      type: String,
      default: 'middle'
    },
    name: String,
    type: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const store = useStore();
    const network = computed(() => {
      return props.name || store.state._config.contractConfig.network;
    });
    return (_ctx, _cache) => {
      return _unref(network) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['flex items-center justify-center', __props.size])
      }, [_createElementVNode("img", {
        class: "h-full",
        src: '/images/network/' + _unref(network).toLowerCase() + (__props.type ? '-' + __props.type : '') + '.svg'
      }, null, 8, _hoisted_1)], 2)) : _createCommentVNode("", true);
    };
  }
};